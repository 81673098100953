body {
  background-color: #202020;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #ffffff;
  font-size: 24px;
}

.container {
  display: flex;
  justify-content: space-around;
}

.left-column, .center-column, .right-column {
  flex: 1;
  padding: 10px;
}

.dropzone {
  margin: 20px auto;
  padding: 20px;
  border: 2px dashed #ffffff;
  background-color: #303030;
  cursor: pointer;
  width: 300px;
  border-radius: 10px;
  color: #ffffff;
}

.dropzone p {
  margin: 0;
}

.clear-button {
  margin: 10px 0;
  padding: 10px;
  background-color: #d9534f;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.clear-button:hover {
  background-color: #b52e2a;
}

.image-list, .saved-sketches {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image-item, .sketch-item {
  position: relative;
  border: 2px solid #303030;
  border-radius: 10px;
  overflow: hidden;
}

.image-item img, .sketch-item img {
  display: block;
  max-width: 150px;
  max-height: 150px;
  cursor: pointer;
}

.remove-button, .download-button {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d9534f;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 80%;
}

.remove-button:hover {
  background-color: #b52e2a;
}

.download-button {
  background-color: #28a745;
}

.download-button:hover {
  background-color: #218838;
}

.editor {
  margin: 20px;
  padding: 20px;
  border: 2px solid #303030;
  background-color: #282828;
  border-radius: 10px;
  display: inline-block;
}

.controls {
  margin: 20px 0;
  text-align: left;
}

.controls label {
  margin: 0 10px;
  display: inline-block;
}

.controls input[type="range"] {
  vertical-align: middle;
  margin-left: 10px;
  appearance: none;
  background: #444444;
  border-radius: 5px;
  height: 6px;
}

.controls input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 50%;
}

.controls input[type="color"] {
  vertical-align: middle;
  margin-left: 10px;
  border: none;
  background: none;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #999999;
  cursor: not-allowed;
}

.save-button {
  background-color: #28a745;
}

.save-button:hover {
  background-color: #218838;
}
